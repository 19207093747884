document.addEventListener("DOMContentLoaded", () => {
    const { trackQuoteRequested, trackLocationSearched, addFormHiddenFields, trackLeadSubmitted } = SegmentEvents;

    trackQuoteRequested();
    trackLocationSearched();
    trackLeadSubmitted();

    analytics.ready(function () {
        addFormHiddenFields();
    });
});

const SegmentEvents = (function() {
    function getTargetPath(targetPaths) {
        const locationPathnameArr = window.location.pathname.split("/");

        for (let index = 0; index < targetPaths.length; index++) {
            const path = targetPaths[index];

            if (locationPathnameArr.includes(path)) return path;
        }

        return false;
    }

    function trackQuoteRequested() {
        const quoteType = getQuoteType();

        if (quoteType) {
            const form = document.querySelector('form[data-tracking="quote-form"]');

            //Quote 2.0
            const formSubmitBtn = form.querySelector('input[data-form="submit-btn"]');
            formSubmitBtn && formSubmitBtn.addEventListener("click", () => {
                triggerQuoteRequested(form, quoteType);
            });

            //Quote 1.0
            form.addEventListener("submit", () => {
                triggerQuoteRequested(form, quoteType);
            });
        }
    }

    function triggerQuoteRequested(form, quoteType) {
        const formName = form.dataset.name;
        const formData = new FormData(form);

        const traits = {
            form_name: formName,
            quote_type: quoteType,
            email: formData.get("email"),
            first_name: formData.get("first_name"),
            last_name: formData.get("last_name"),
            phone: getPhoneNumber(formData),
            company_name: getCompanyName(formData),
            page: formData.has("Current Page") ? formData.get("Current Page") : "",
            address: getAddress(formData),
        };

        analytics.track("Quote Requested", traits);
    }

    function getPhoneNumber(formData) {
        if (formData.has("Phone-Number")) return formData.get("Phone-Number");

        if (formData.has("Phone")) return formData.get("Phone");

        if (formData.has("phone")) return formData.get("phone");

        return "";
    }

    function getCompanyName(formData) {
        if (formData.has("Company-Name")) return formData.get("Company-Name");

        if (formData.has("Company")) return formData.get("Company");

        if (formData.has("Organisation-name"))
            return formData.get("Organisation-name");

        return "";
    }

    function getAddress(formData) {
        if (formData.has("Address")) return formData.get("Address");

        if (formData.has("Office-address")) return formData.get("Office-address");

        return "";
    }

    function trackQuoteStarted() {
        const quoteType = getQuoteType();

        if (quoteType) {
            analytics.track("Quote Started", {
                quote_type: quoteType,
            });
        }
    }

    function getQuoteType() {
        if (window.location.pathname.includes('/l/go')) return "quote 2.0";

        const locationPathnameArr = window.location.pathname.split("/");
        const quoteTypes = ["catering", "event", "kitchen", "oml"];

        for (let index = 0; index < locationPathnameArr.length; index++) {
            const path = locationPathnameArr[index];

            if (path == "quote" && locationPathnameArr[index + 1]) {
                quote = locationPathnameArr[index + 1];

                return quoteTypes.find((q) => quote.includes(q));
            }
        }

        return "";
    }

    function trackLocationSearched() {
        let addressForms = document.querySelectorAll(
            '[data-cta="autocomplete"] form'
        );

        if (addressForms.length == 0) {
            addressForms = document.querySelectorAll('[data-cta="autocomplete"]');
        }

        addressForms.forEach((form) => {
            if (!form.action.includes("catering.eatfirst.com.au")) return;

            const addressSearchBtn = form.querySelector('input[type="submit"]');
            const addressField = form.querySelector('[data-name="Address"]');

            addressSearchBtn.addEventListener("click", () => {
                analytics.track("Location Searched", {
                    address: addressField.value,
                });
            });
        });
    }

    function trackLeadSubmitted() {
        const leadForms = document.querySelectorAll('form[data-tracking="lead-form"]');

        leadForms.forEach(form => {
            form.addEventListener("submit", () => {
                triggerLeadSubmitted(form)
            });
        });
        
    }

    function triggerLeadSubmitted(form) {
        const formData = new FormData(form);

        if (!formData.has('email')) return;

        let traits = {
            form_name: form.dataset.name,
            email: formData.has("Email") ? formData.get("Email") : formData.get("email"),
            phone: getPhoneNumber(formData),
            company_name: getCompanyName(formData),
            page: window.location.pathname,
        };

        if (formData.has("Name")) {
            traits["name"] = formData.get("Name");
        } else if (formData.has("name")) {
            traits["name"] = formData.get("name");
        } else if (formData.has("First-Name") && formData.has("Last-Name")) {
            traits["first_name"] = formData.get("First-Name");
            traits["last_name"] = formData.get("Last-Name");
        } else if (formData.has("first_name") && formData.has("last_name")) {
            traits["first_name"] = formData.get("first_name");
            traits["last_name"] = formData.get("last_name");
        }

        const address = getAddress(formData);
        if (address) {
            traits['address'] = address;
        }

        analytics.track("Lead Submitted", traits);
    }

    function trackLeadQualified() {
        const targetPaths = ["meal-plans"];
        const targetPath = getTargetPath(targetPaths);

        if (targetPath) {
            const form = document.querySelector('form[data-name$="Form"]');

            form.addEventListener("submit", () => {
                const formData = new FormData(form);

                // Only update traits if not logged in
                if (!analytics.user().id()) {
                    analytics.identify({
                        first_name: formData.has("First-Name") ? formData.get("First-Name") : formData.get("first_name"),
                        last_name: formData.has("Last-Name") ? formData.get("Last-Name") : formData.get("last_name"),
                        email: formData.has("Email") ? formData.get("Email") : formData.get("email"),
                    });
                }

                analytics.track("Lead Qualified", {
                    qual_type: "meal-plan",
                });

            });
        }

    }

    function addFormHiddenFields() {
        const trackingForms = document.querySelectorAll("form");
        const pageTitleCleaned = document.title.split("|")[0].trim();
        const user = analytics.user ? analytics.user() : null;

        let values = {
            URL: window.location.origin + window.location.pathname,
            Query: window.location.search,
            "Segment User ID": user ? user.id() : "",
            "Segment Anonymous ID": user ? user.anonymousId() : "",
            "Current Page": pageTitleCleaned,
            Referrer: document.referrer,
            "User Agent": navigator.userAgent,
        };

        trackingForms.forEach(form => {
            const dataTracking = form.dataset.name;
            values["Tracking"] = dataTracking;

            Object.entries(values).forEach(([name, value]) =>
                appendInputFieldToForm(form, name, value)
            );
        });
    }

    function appendInputFieldToForm(form, name, value) {
        if (!form) return;

        const hiddenInput = document.createElement("input");

        hiddenInput.type = "hidden";
        hiddenInput.name = name;
        hiddenInput.dataset.name = name;
        hiddenInput.value = value;

        form.appendChild(hiddenInput);
    }

    return {
        trackQuoteRequested,
        trackLocationSearched,
        addFormHiddenFields,
        trackQuoteStarted,
        trackLeadSubmitted,
        trackLeadQualified,
    }
})();